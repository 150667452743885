@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes scale-right {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}
@keyframes scale-right {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

body #root #app {
  .footer {
    padding: 1em;
    font-family: 'Courier New', Courier, monospace;
    font-size: 0.8em;
    overflow-y: scroll;
    overflow-x: hidden;
    overflow-anchor: none;
    background-color: #000000dd;
    color: var(--white2);
    position: absolute;
    width: 100%;
    transition: width 0.35s ease-out, height 0.35s ease-out;

    &.is-maximised {
      height: 25%;
      bottom: 0;
    }
    &.is-minimised {
      background-color: #00000075;
      border-top-right-radius: 0.5em;
      height: 32px;
      width: 36px;
      left: 0;
      bottom: 0;
      padding: 0.5em 1em;
      overflow: hidden;
      p {
        display: none;
      }

      &:hover {
        background-color: #000000dd;
      }

      &.has-new-item {
        width: 100%;
        border-radius: 0;
        p {
          display: initial;
        }
      }
    }

    span.collapse-expand {
      right: 10px;
      padding: 0;
      margin: 0;
      max-height: 16px;
      max-width: 16px;

      &:hover {
        color: var(--orange);
      }

      &.collapsed {
        position: absolute;
        top: 10px;
      }
      &.expanded {
        position: fixed;
      }
      .is-pinned {
        color: var(--orange);
      }
    }

    .logitem-error {
      color: var(--red);
    }
    .logitem-debug {
      color: var(--grey3);
    }
    .logitem-pending {
      color: var(--orange3);
    }
    .logitem-success {
      color: var(--green);
    }
    p {
      word-wrap: break-word;
      margin-bottom: 0.5em;
    }
    #anchor {
      overflow-anchor: auto;
      height: 1px;
    }
  }
}
