@import url('https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');

$tabs-boxed-link-active-background-color: transparent;
$tabs-boxed-link-hover-background-color: transparent;
$tabs-boxed-link-hover-border-bottom-color: transparent;
$tabs-boxed-link-active-border-color: transparent;
$tabs-boxed-link-active-border-bottom-color: transparent;
$tabs-link-active-color: black;

@import 'bulma/sass/utilities/_all';
@import 'bulma/sass/base/_all';
@import 'bulma/sass/elements/_all';
@import 'bulma/sass/form/_all';
@import 'bulma/sass/components/_all';
@import 'bulma/sass/grid/_all';
@import 'bulma/sass/helpers/_all';
@import 'bulma/sass/layout/_all';
@import 'bulma-switch';
@import '~@creativebulma/bulma-divider';
@import '~@creativebulma/bulma-collapsible';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

:root {
  --blue: #87ceeb;
  --blue2: #3850b7;
  --blue3: blue;
  --brown: #a16f47;
  --brown2: #795548;
  --green: green;
  --red: #e42e52;
  --orange: #fdc614;
  --orange2: #ff9702;
  --orange3: #c77605;
  --purple: #534d74;
  --white: #f0efeb;
  --white1: #cececb;
  --white2: #b6b5b2;
  --grey1: #212329e0;
  --grey2: #2e313d;
  --grey3: #525563;
  --grey4: #6f7283;
  --grey5: #a3a3a3;
  --dark: #1d1f24;
  --black: #191715;
  --color-initial: var(--white);
  --color-primary: var(--white);
  --color-secondary: var(--purple);

  --bg-grey1: var(--grey1);
  --bg-grey2: var(--grey2);
  --bg-grey3: var(--grey3);
  --bg-grey4: var(--grey4);
  --bg-grey5: var(--grey5);
  --bd-grey1: var(--grey4);
  --bd-grey2: var(--dark);

  --light-text: var(--white1);
  --light2-text: var(--white2);
  --light3-text: var(--grey5);
  --warning-text: var(--red);
  --action-text: var(--orange2);
  --action-text-hover: var(--orange);
  --light-link-blue: #020202;

  --text: var(--black);
  --link-blue: #3273dc;
}

@mixin radial-background($from, $to) {
  background: radial-gradient(circle, $from 50%, $to 150%);
}

@mixin transition-opacity($delay, $delayed: false) {
  transition: opacity $delay;

  @if $delayed {
    transition-delay: $delay;
  }
}

html {
  background-color: var(--black);
  overflow: hidden;

  &.has-navbar-fixed-top {
    padding-top: 74px;
  }

  body {
    margin: 0;
    font-family: 'Muli', 'Open Sans', sans-serif;

    ::-webkit-scrollbar {
      width: 8px !important;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
      background-color: #ff9702c1;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-track {
      margin: 2px 0;
    }

    .is-full-height {
      height: 100%;
    }

    .notification {
      width: 100% !important;
      margin-bottom: 10px !important;
      background-color: #363636 !important;

      &:before {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        margin-right: 10px !important;
      }

      &.is-success {
        color: #48c78e !important;
        &:before {
          content: '\f05a';
        }
      }

      &.is-warning {
        color: #ffe08a !important;
        &:before {
          content: '\f06a';
        }
      }

      &.is-danger {
        color: #f14668 !important;
        &:before {
          content: '\f7ba';
        }
      }
    }

    #root {
      #app {
        .is-fullwidth {
          width: 100%;
        }

        .noselect {
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome, Edge, Opera and Firefox */
        }

        .has-action-text {
          color: var(--action-text);
          &:hover {
            color: var(--action-text-hover);
          }
        }

        button.has-action-text {
          background-color: transparent;
          border: none;
          font: inherit;
          padding: 0;
          &:hover {
            background-color: transparent;
          }
        }

        button.is-tiny, input.is-tiny {
          font-size: 0.55rem;
        }

        .has-link-text {
          color: var(--link-blue) !important;
        }

        .has-light-text {
          color: var(--light2-text) !important;
        }

        .has-light3-text {
          color: var(--light3-text) !important;
        }

        .has-warning-text {
          color: var(--warning-text) !important;
        }

        .has-tooltip-text {
          color: #b6b5b25c !important;
        }

        .select::after {
          border-color: var(--orange2);
        }

        .modal-close {
          position: absolute;
          &.is-button {
            right: 0;
            top: 0;
            border-radius: 0;
            border-bottom-left-radius: 10px;
            border-top-right-radius: 10px;
            background: #00000061;
          }

          &:hover {
            &::before,
            &::after {
              background-color: var(--orange) !important;
            }
          }
        }

        .button,
        select {
          &.is-white {
            border-radius: 0;
            border: none;
          }
          &.is-land {
            border: none;
            background-color: var(--brown2);
          }
          &.is-corn {
            border: none;
            background-color: var(--orange);
          }
          &.is-gray {
            border: none;
            border-radius: 0;
            background-color: #a9a9a9;
          }
          &.is-darkgray {
            border-radius: 0;
            border: none;
            background-color: #808080;
            color: var(--white);
          }
          &.is-darkergray {
            border-radius: 0;
            border: none;
            background-color: #4a4a4a;
            color: var(--white);
          }
          &.is-dull {
            background-color: var(--bg-grey2);
            border-color: var(--grey5);
            color: var(--light-text);
          }
          &.is-action {
            background-color: var(--dark);
            border-color: var(--orange2);
            color: var(--orange2);
            &:hover {
              border-color: var(--orange);
            }
          }
          &.is-control,
          &.is-active-control,
          &.is-cancel {
            background-color: var(--dark);
            border: none;
            color: var(--white);
          }
          &.is-active-control {
            color: var(--orange2);
          }
          &.is-transparent {
            background: transparent;
            border: none;
            color: var(--white);
          }
          &.is-transparent-action {
            background: var(--grey1);
            border: none;
            color: var(--orange2);
            box-shadow: none !important;
            &:hover {
              color: var(--orange);
            }
          }
          &.is-inactive-action {
            background: transparent;
            border: none;
            color: var(--grey3);
            box-shadow: none !important;
            &:hover {
              color: var(--orange);
            }
          }
          &.is-ghost-action {
            background: transparent;
            border: none;
            color: var(--orange2);
            box-shadow: none !important;
            &:hover {
              color: var(--orange);
            }
          }
          &.is-ghost {
            outline: transparent;
            box-shadow: none !important;
            color: var(--link-blue);
          }
        }

        input {
          &.is-action {
            background-color: var(--black);
            border-color: var(--orange2);
            color: var(--white);
          }
        }

        progress::-webkit-progress-bar {
          background-color: var(--black);
        }
        progress {
          background-color: var(--black);
        }

        progress::-moz-progress-bar {
          background: linear-gradient(to right, var(--green) 0%, var(--red) 100%) fixed !important;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
        }
        progress::-moz-progress-value {
          background: linear-gradient(to right, var(--green) 0%, var(--red) 100%) fixed !important;
          transition: all 0.5s;
        }
        progress::-webkit-progress-value {
          background: linear-gradient(to right, var(--green) 0%, var(--red) 100%) fixed !important;
          -webkit-transition: all 0.5s;
          transition: all 0.5s;
        }

        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: var(--grey3);
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: var(--grey3);
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: var(--grey3);
        }

        .fill-remaining-space {
          flex: auto;
        }

        .footer {
          margin: 0 auto;
          z-index: 1;

          .dot {
            @include transition-opacity(250ms);
          }
        }

        .noselect {
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                          supported by Chrome, Edge, Opera and Firefox */
        }

        .modal-content,
        .modal-card {
          @media screen and (max-width: 400px) {
            max-height: calc(100vh - 60px);
          }
        }
      }
    }
  }
}
