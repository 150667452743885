@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

body {
  #root {
    #app {
      #content-container {
        .characters {
          width: auto;
          padding: 10px;
          &.zoomed-farmer {
            overflow: hidden;
            .modal-close {
              -webkit-animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
              animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
              right: 0;
              top: 0;
            }

            -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

            .stage:hover {
              animation: none !important;
            }

            .character-boost-remaining .info {
              height: 19px !important;
              width: 50px !important;
            }
          }
        }
        .employ-farmer {
          position: absolute;
          margin: auto;
          top: 43%;
          left: 0;
          right: 0;
          font-size: 1.1rem !important;
        }
        .farmers {
          &.dummy {
            filter: opacity(0.1) blur(1px);
          }

          .swiper {
            padding-top: 4px;
            padding-bottom: 5px;
            .swiper-slide {
              width: auto;
            }

            .swiper-button-next,
            .swiper-button-prev {
              color: var(--action-text);
            }
          }

          .stage {
            max-width: 160px;
            box-sizing: border-box;
            margin-top: 0 !important;
            margin-bottom: 15px !important;

            &:hover {
              transform-origin: center center;
              animation: charscale 200ms ease-in-out forwards;
            }
            @keyframes farmerscale {
              to {
                transform: scale(1.01);
              }
            }

            .lv1-stage-action {
              margin-top: -36px;
              height: 22px;
              width: 98%;
            }

            .stage-action {
              .activate-button {
                display: none;
                border: none !important;
                font-size: 0.8em;
              }
            }

            &:hover {
              .stage-action {
                .activate-button {
                  display: block;
                  width: 98%;
                  margin: auto;
                  background: rgba(0, 0, 0, 0.65);
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;

                  -webkit-animation: scale-up-ver-bottom 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                  animation: scale-up-ver-bottom 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

                  span {
                    -webkit-animation: text-focus-in 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
                    animation: text-focus-in 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
                  }
                }
              }
            }

            .card {
              .card-zoom {
                display: none;
              }

              -webkit-animation: glow 2s infinite alternate;
              animation: glow 2s infinite alternate;

              padding: 0.4em !important;

              &.legendary {
                box-shadow: 1px 1px 10px 2px #ffd700cc;
              }
              &.epic {
                box-shadow: 1px 1px 6px 1px #eeeeeecc;
              }
              &.rare {
                box-shadow: 1px 1px 6px 1px #936d3bcc;
              }
              &::after {
                content: '';
                position: absolute;
                right: -0.1px;
                left: -0.1px;
                top: -0.1px;
                bottom: -0.1px;
                border-radius: 15px;
              }

              background-repeat: no-repeat;
              background-size: 450%;
              background-position: top left;
              box-shadow: -2px 3px 10px -2px rgba(0, 0, 0, 0.2);
              border-radius: 10px;

              .card-content {
                .content {
                  .character-title {
                    color: var(--light-text);
                    font-size: 0.5em !important;
                  }
                  .character-boost-remaining,
                  .character-xp,
                  .character-level {
                    background: var(--orange2);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 0.6em;
                    font-weight: 700;
                    opacity: 0.5;
                    padding: 1px;
                    padding-top: 8px !important;

                    .info {
                      display: none;
                    }
                  }
                  .is-size-6 {
                    font-size: 0.4em !important;
                  }
                  .is-size-7 {
                    font-size: 0.3em !important;
                  }
                  color: var(--light-text);

                  .attributes {
                    height: 18px;
                  }

                  .super-stat {
                    color: #ff5656;
                    color: var(--red);
                  }

                  .good-stat {
                    color: #caca50;
                    color: var(--action-text);
                  }

                  .attributes {
                    margin-top: -5px;
                  }
                  .tag {
                    font-size: 0.25em !important;

                    &.Special {
                      background: linear-gradient(purple, rgb(59, 1, 59));
                    }
                    &.Image {
                      background: linear-gradient(#188f16, #003000);
                    }
                    &.Stat {
                      background: linear-gradient(var(--red), rgb(56, 5, 1));
                    }
                  }
                }
              }

              div {
                width: 100%;
              }

              &.spinner:hover {
                -webkit-animation-name: spinner;
                -webkit-animation-timing-function: linear;
                -webkit-animation-iteration-count: infinite;
                -webkit-animation-duration: 6s;

                animation-name: spinner;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-duration: 6s;

                -webkit-transform-style: preserve-3d;
                -moz-transform-style: preserve-3d;
                -ms-transform-style: preserve-3d;
                transform-style: preserve-3d;
              }

              .image {
                img {
                  border-radius: 6px;
                }

                .nft-sample {
                  color: #ffffff2b;
                  position: absolute;
                  font-size: 4em;
                  top: 80px;
                  left: 15px;
                  -moz-transform: rotate(-45deg) translateX(0px) scale(1);
                  -o-transform: rotate(-45deg) translateX(0px) scale(1);
                  -webkit-transform: rotate(-45deg) translateX(0px) scale(1);
                  transform: rotate(-45deg) translateX(0px) scale(1);
                }
              }
            }
          }
        }
      }
    }
  }
}
