body {
  #root {
    #app {
      #content-container {
        #content {
          .swiper {
            overflow: visible;

            .swiper-slide {
              width: auto;
            }

            .swiper-button-next,
            .swiper-button-prev {
              color: var(--action-text);
            }
          }

          .character-mint {
            width: 350px;
          }

          .buy-buttons {
            ul {
              list-style: none;
            }

            input {
              text-align: center;
            }
            .minus-button {
              border-bottom-left-radius: 0;
            }
            .plus-button {
              border-bottom-right-radius: 0;
            }
            .boost-button {
              margin-top: -1px;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
            p {
              color: var(--light-text);
            }
          }

          .countdown-container {
            .countdown {
              margin-top: -0.3em;
              display: flex;

              .ticker {
                margin: 0 0.5em;

                .digits {
                  display: flex;

                  .tick {
                    font-size: 1.5em;
                    font-weight: 500;
                  }

                  .tick-flip-panel {
                    background-color: #00000075;
                  }
                }

                &.hours,
                &.mins {
                  .tick-flip-panel {
                    background-color: #cc0f3550;
                  }
                }

                .units {
                  font-size: 0.7rem;
                  text-align: center;
                  color: grey;
                }
              }
            }
          }
        }
      }
    }
  }
}
