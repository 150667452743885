body {
  #root {
    #app {
      #content-container {
        .characters {
          overflow: visible;

          .stage {
            max-width: 275px;
            box-sizing: border-box;
            margin-top: 6px;

            .lv1-stage-action,
            .lv2-stage-action,
            .lv3-stage-action {
              width: 98%;
            }

            .lv1-stage-action {
              margin-top: -35px;
              height: 30px;
            }

            .lv2-stage-action {
              margin-top: -67px;
              height: 60px;
            }

            .lv3-stage-action {
              margin-top: -97px;
              height: 90px;
            }

            .stage-action {
              .activate-button {
                border-radius: 0;
                background: rgba(0, 0, 0, 0.65) !important;
                display: none;
                border: none !important;
                font-size: 0.8em;
              }

              .tooltip {
                .tooltip-text {
                  filter: drop-shadow(1px 2px 3px black);
                  display: none;
                  text-align: center;
                  padding: 10px;
                  border-radius: 6px;
                  position: absolute;
                  z-index: 11000;
                  left: 50%;
                  margin-top: 0px;
                  margin-left: -100px;
                  background-color: #000;
                  border-color: transparent;
                  color: var(--light-text);
                  font-size: 1em;
                  font-weight: 500;
                  word-wrap: break-word;
                  width: 188px;
                  white-space: -moz-pre-wrap !important;
                  white-space: -pre-wrap;
                  white-space: -o-pre-wrap;
                  white-space: -webkit-pre-wrap;
                  white-space: normal;
                }
                .tooltip-text-middle-drop {
                  margin-top: 34px;
                }
                .tooltip-text-up {
                  margin-top: -95px;
                }
                &:hover {
                  .tooltip-text {
                    display: block;
                    animation-delay: 0.5s;
                  }
                }
              }
            }

            &:hover {
              .stage-action {
                .activate-button {
                  display: block;
                  width: 100%;
                  background: rgba(0, 0, 0, 0.65);

                  -webkit-animation: scale-up-ver-bottom 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
                  animation: scale-up-ver-bottom 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

                  span {
                    -webkit-animation: text-focus-in 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
                    animation: text-focus-in 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
                  }
                }
              }

              transform-origin: bottom center;
              animation: charscale 200ms ease-in-out forwards;

              .character-boost-remaining,
              .character-xp,
              .character-level {
                .fa-fw {
                  display: none !important;
                }
                .info {
                  display: initial !important;
                }
              }
            }

            @keyframes charscale {
              to {
                transform: scale(1.03);
              }
            }

            .card {
              -webkit-animation: glow 2s infinite alternate;
              animation: glow 2s infinite alternate;
              background-repeat: no-repeat;
              background-size: 450%;
              background-position: top left;
              border-radius: 15px;
              position: relative;
              overflow: hidden;
              &::after {
                content: '';
                position: absolute;
                right: -0.1px;
                left: -0.1px;
                top: -0.1px;
                bottom: -0.1px;
                border-radius: 15px;
              }

              &.is-loading {
                .card-image {
                  .image {
                    img {
                      filter: grayscale(1);
                    }
                  }
                }
                &::after {
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  transform: translateX(-100%);
                  background-image: linear-gradient(
                    90deg,
                    rgba(#ccc, 0) 0,
                    rgba(#ccc, 0.2) 20%,
                    rgba(#ccc, 0.5) 60%,
                    rgba(#ccc, 0)
                  );
                  animation: shimmer 2s infinite;
                  content: '';
                }

                @keyframes shimmer {
                  100% {
                    transform: translateX(100%);
                  }
                }
              }
              .card-image {
                height: 250px;
                width: 250px;
                .image {
                  height: 100% !important;
                  img {
                    height: 100% !important;
                  }
                }
              }

              .card-content {
                .content {
                  .character-title {
                    color: var(--light-text);
                    font-size: 1em !important;
                  }
                  .character-boost-remaining,
                  .character-xp,
                  .character-level {
                    background: var(--orange2);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 0.7em;
                    font-weight: 700;
                    opacity: 0.5;
                    padding: 1px;

                    span {
                      line-height: 15px;
                    }

                    .info {
                      font-size: 0.9em;
                      height: 15px;
                      display: none;
                    }

                    .info-small {
                      font-size: 8px;
                    }
                  }

                  .character-boost-remaining {
                    i {
                      padding-top: 2px;
                    }
                  }
                  .is-size-6 {
                    font-size: 0.7em !important;
                  }
                  .is-size-7 {
                    font-size: 0.6em !important;
                  }
                  color: var(--light-text);

                  .attributes {
                    height: 28px;
                    padding-top: 3px;

                    .tag {
                      font-size: 0.5em !important;
                      margin-right: 3px;
                      border-radius: 10px;

                      &.Unknown {
                        background: linear-gradient(rgb(209, 160, 0), rgb(167, 97, 0));
                      }
                      &.Special {
                        background: linear-gradient(purple, rgb(59, 1, 59));
                      }
                      &.Image {
                        background: linear-gradient(#188f16, #003000);
                      }
                      &.Stat {
                        background: linear-gradient(var(--red), rgb(56, 5, 1));
                      }
                    }
                  }

                  .stats {
                    div {
                      width: 100%;
                      height: 15px;

                      .super-stat {
                        color: #ff5656;
                        color: var(--red);
                      }

                      .good-stat {
                        color: #caca50;
                        color: var(--action-text);
                      }
                    }
                  }

                  .bar-stat-container {
                    border-radius: 3px;
                    .bar-stat-background {
                      height: 2px;
                      margin-top: 14px;
                      margin-bottom: -2px;
                      background-color: #00800050;
                      border-top-left-radius: 3px;
                      border-bottom-left-radius: 3px;
                    }
                    .bar-stat-background-med {
                      background-color: #c7760550;
                    }
                    .bar-stat-background-low {
                      background-color: #e42e5250;
                    }
                    .bar-stat-background-full {
                      border-radius: 3px;
                    }
                    .bar-stat {
                      height: 14px;
                      transform: translateY(-14px);
                      border-radius: 3px;
                    }
                  }
                }
              }

              canvas {
                border-radius: 10px;
              }

              .image {
                img {
                  border-radius: 10px;
                }

                .nft-sample {
                  color: #ffffff2b;
                  position: absolute;
                  font-size: 4em;
                  top: 80px;
                  left: 15px;
                  -moz-transform: rotate(-45deg) translateX(0px) scale(1);
                  -o-transform: rotate(-45deg) translateX(0px) scale(1);
                  -webkit-transform: rotate(-45deg) translateX(0px) scale(1);
                  transform: rotate(-45deg) translateX(0px) scale(1);
                }
              }
            }

            &.character-type-mercenary {
              .card {
                box-shadow: none !important;
              }
            }
            &.character-type-citizen {
              .card {
                box-shadow: -4px 5px 15px -3px rgba(0, 0, 0, 0.2) !important;

                &.unknown {
                  box-shadow: 1px 1px 10px 2px var(--orange2) !important;
                }
                &.unique {
                  box-shadow: 1px 1px 10px 2px #ffd700cc !important;
                }
                &.legendary {
                  box-shadow: 1px 1px 10px 2px #ffd700cc !important;
                }
                &.epic {
                  box-shadow: 1px 1px 6px 1px #eeeeeecc !important;
                }
                &.rare {
                  box-shadow: 1px 1px 6px 1px #936d3bcc !important;
                }
              }
            }
          }
        }

        .modal-background {
          background-color: rgba(10, 10, 10, 0.9);
        }

        .character-boost {
          width: 380px;

          .modal-close {
            position: absolute;
            right: 5px;
            top: 5px;
          }

          .card {
            border: solid 1px var(--bd-grey2);
            border-radius: 10px;

            background-color: #000;
            box-shadow: -4px 5px 15px -3px rgb(0, 0, 0 / 20%);

            padding: 1.3em 1em 1.6em;

            .character-boost-title {
              font-size: 1.5rem;
              font-weight: 300;
              color: var(--light2-text) !important;
            }

            .boosting {
              color: red;
            }

            .character-boost-stats {
              input {
                text-align: center;
              }
              .minus-button {
                border-bottom-left-radius: 0;
              }
              .plus-button {
                border-bottom-right-radius: 0;
              }
              .boost-button {
                margin-top: -1px;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
              }
            }

            .character-stat-list-item {
              button {
                height: 30px;
              }
              &.is-disabled {
                button {
                  opacity: 0.5;
                }
              }
              .boosted {
                color: green;
              }
            }
          }
        }
      }
    }
  }
}
