$cube-size: 160px; //40vmin
$cube-after-size: 160.8px; //40.2vmin
$cube-transform: 80.4px; // 20.1 vmin
$cube-translate: 80px; // 20vmin

$translate-y-size: $cube-transform;
$translate-z-size: 48.48px; // =12.12

$shadow-size: 2px; // .5
$shadow-size-inner: -0.4px; // .1

.unrevealed-outer-container {
  position: relative;
}

.unrevealed-scaled-container {
  position: absolute;
  transform: scale(0.5);
}

.unrevealed {
  background-color: #000000ab;
  height: 100%;
  border-radius: 10px;

  .cube {
    position: relative;
    display: block;
    width: $cube-size;
    height: $cube-size;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: spin 5000ms linear infinite;
    -moz-animation: spin 5000ms linear infinite;
    -ms-animation: spin 5000ms linear infinite;
    -o-animation: spin 5000ms linear infinite;
    animation: spin 5000ms linear infinite;
  }

  .cube:after {
    -webkit-contain: strict;
    -moz-contain: strict;
    -ms-contain: strict;
    -o-contain: strict;
    contain: strict;
    position: absolute;
    top: $shadow-size-inner;
    right: $shadow-size-inner;
    bottom: $shadow-size-inner;
    left: $shadow-size-inner;
    display: block;
    content: ' ';
    background-color: rgba(0, 0, 0, 0.5);
    width: $cube-after-size;
    height: $cube-after-size;
    overflow: hidden;
    -webkit-box-shadow: 0 0 $shadow-size #000;
    -moz-box-shadow: 0 0 $shadow-size #000;
    -ms-box-shadow: 0 0 $shadow-size #000;
    -o-box-shadow: 0 0 $shadow-size #000;
    box-shadow: 0 0 $shadow-size #000;
    -webkit-transform: rotateX(90deg) translateY($translate-y-size) translateZ($translate-z-size);
    -moz-transform: rotateX(90deg) translateY($translate-y-size) translateZ($translate-z-size);
    -ms-transform: rotateX(90deg) translateY($translate-y-size) translateZ($translate-z-size);
    -o-transform: rotateX(90deg) translateY($translate-y-size) translateZ($translate-z-size);
    transform: rotateX(90deg) translateY($translate-y-size) translateZ($translate-z-size);
    -webkit-transform-origin: $cube-transform $cube-after-size;
    -moz-transform-origin: $cube-transform $cube-after-size;
    -ms-transform-origin: $cube-transform $cube-after-size;
    -o-transform-origin: $cube-transform $cube-after-size;
    transform-origin: $cube-transform $cube-after-size;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    -ms-opacity: 0.2;
    -o-opacity: 0.2;
    opacity: 0.2;
  }

  .face,
  .shade,
  svg {
    -webkit-contain: strict;
    -moz-contain: strict;
    -ms-contain: strict;
    -o-contain: strict;
    contain: strict;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: $cube-size;
    height: $cube-size;
    overflow: hidden;
  }

  svg {
    z-index: 1;
  }

  .front {
    -webkit-transform: rotateY(0) translateZ($cube-translate);
    -moz-transform: rotateY(0) translateZ($cube-translate);
    -ms-transform: rotateY(0) translateZ($cube-translate);
    -o-transform: rotateY(0) translateZ($cube-translate);
    transform: rotateY(0) translateZ($cube-translate);
  }

  .front > .shade {
    -webkit-animation: shade 5000ms -7500ms linear infinite;
    -moz-animation: shade 5000ms -7500ms linear infinite;
    -ms-animation: shade 5000ms -7500ms linear infinite;
    -o-animation: shade 5000ms -7500ms linear infinite;
    animation: shade 5000ms -7500ms linear infinite;
  }

  .right {
    -webkit-transform: rotateY(-270deg) translateX($cube-translate);
    -moz-transform: rotateY(-270deg) translateX($cube-translate);
    -ms-transform: rotateY(-270deg) translateX($cube-translate);
    -o-transform: rotateY(-270deg) translateX($cube-translate);
    transform: rotateY(-270deg) translateX($cube-translate);
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    -ms-transform-origin: top right;
    -o-transform-origin: top right;
    transform-origin: top right;
  }

  .right > .shade {
    -webkit-animation: shade 5000ms -6250ms linear infinite;
    -moz-animation: shade 5000ms -6250ms linear infinite;
    -ms-animation: shade 5000ms -6250ms linear infinite;
    -o-animation: shade 5000ms -6250ms linear infinite;
    animation: shade 5000ms -6250ms linear infinite;
  }

  .back {
    -webkit-transform: rotateY(180deg) translateZ($cube-translate);
    -moz-transform: rotateY(180deg) translateZ($cube-translate);
    -ms-transform: rotateY(180deg) translateZ($cube-translate);
    -o-transform: rotateY(180deg) translateZ($cube-translate);
    transform: rotateY(180deg) translateZ($cube-translate);
  }

  .back > .shade {
    -webkit-animation: shade 5000ms -5000ms linear infinite;
    -moz-animation: shade 5000ms -5000ms linear infinite;
    -ms-animation: shade 5000ms -5000ms linear infinite;
    -o-animation: shade 5000ms -5000ms linear infinite;
    animation: shade 5000ms -5000ms linear infinite;
  }

  .left {
    -webkit-transform: rotateY(270deg) translateX(-$cube-translate);
    -moz-transform: rotateY(270deg) translateX(-$cube-translate);
    -ms-transform: rotateY(270deg) translateX(-$cube-translate);
    -o-transform: rotateY(270deg) translateX(-$cube-translate);
    transform: rotateY(270deg) translateX(-$cube-translate);
    -webkit-transform-origin: center left;
    -moz-transform-origin: center left;
    -ms-transform-origin: center left;
    -o-transform-origin: center left;
    transform-origin: center left;
  }

  .left > .shade {
    -webkit-animation: shade 5000ms -3750ms linear infinite;
    -moz-animation: shade 5000ms -3750ms linear infinite;
    -ms-animation: shade 5000ms -3750ms linear infinite;
    -o-animation: shade 5000ms -3750ms linear infinite;
    animation: shade 5000ms -3750ms linear infinite;
  }

  .top {
    -webkit-transform: rotateX(-90deg) translateY(-$cube-translate);
    -moz-transform: rotateX(-90deg) translateY(-$cube-translate);
    -ms-transform: rotateX(-90deg) translateY(-$cube-translate);
    -o-transform: rotateX(-90deg) translateY(-$cube-translate);
    transform: rotateX(-90deg) translateY(-$cube-translate);
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
  }

  .bottom {
    -webkit-transform: rotateX(90deg) translateY($cube-translate);
    -moz-transform: rotateX(90deg) translateY($cube-translate);
    -ms-transform: rotateX(90deg) translateY($cube-translate);
    -o-transform: rotateX(90deg) translateY($cube-translate);
    transform: rotateX(90deg) translateY($cube-translate);
    -webkit-transform-origin: bottom center;
    -moz-transform-origin: bottom center;
    -ms-transform-origin: bottom center;
    -o-transform-origin: bottom center;
    transform-origin: bottom center;
  }

  .shade {
    z-index: 9;
    background-color: #000;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-opacity: 0.00001;
    -moz-opacity: 0.00001;
    -ms-opacity: 0.00001;
    -o-opacity: 0.00001;
    opacity: 0.00001;
    -webkit-will-change: opacity;
    -moz-will-change: opacity;
    -ms-will-change: opacity;
    -o-will-change: opacity;
    will-change: opacity;
  }
}

@-webkit-keyframes shade {
  0%,
  25%,
  75%,
  100% {
    -webkit-opacity: 0.99999;
    opacity: 0.99999;
  }
  50% {
    -webkit-opacity: 0.00001;
    opacity: 0.00001;
  }
}

@-moz-keyframes shade {
  0%,
  25%,
  75%,
  100% {
    -moz-opacity: 0.99999;
    opacity: 0.99999;
  }
  50% {
    -moz-opacity: 0.00001;
    opacity: 0.00001;
  }
}

@-ms-keyframes shade {
  0%,
  25%,
  75%,
  100% {
    -ms-opacity: 0.99999;
    opacity: 0.99999;
  }
  50% {
    -ms-opacity: 0.00001;
    opacity: 0.00001;
  }
}

@-o-keyframes shade {
  0%,
  25%,
  75%,
  100% {
    -o-opacity: 0.99999;
    opacity: 0.99999;
  }
  50% {
    -o-opacity: 0.00001;
    opacity: 0.00001;
  }
}

@keyframes shade {
  0%,
  25%,
  75%,
  100% {
    -webkit-opacity: 0.99999;
    -moz-opacity: 0.99999;
    -ms-opacity: 0.99999;
    -o-opacity: 0.99999;
    opacity: 0.99999;
  }
  50% {
    -webkit-opacity: 0.00001;
    -moz-opacity: 0.00001;
    -ms-opacity: 0.00001;
    -o-opacity: 0.00001;
    opacity: 0.00001;
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotateX(-22deg) rotateY(360deg);
    transform: rotateX(-22deg) rotateY(360deg);
  }

  100% {
    -webkit-transform: rotateX(-22deg) rotateY(0);
    transform: rotateX(-22deg) rotateY(0);
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotateX(-22deg) rotateY(360deg);
    transform: rotateX(-22deg) rotateY(360deg);
  }

  100% {
    -moz-transform: rotateX(-22deg) rotateY(0);
    transform: rotateX(-22deg) rotateY(0);
  }
}

@-ms-keyframes spin {
  0% {
    -ms-transform: rotateX(-22deg) rotateY(360deg);
    transform: rotateX(-22deg) rotateY(360deg);
  }

  100% {
    -ms-transform: rotateX(-22deg) rotateY(0);
    transform: rotateX(-22deg) rotateY(0);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotateX(-22deg) rotateY(360deg);
    transform: rotateX(-22deg) rotateY(360deg);
  }

  100% {
    -o-transform: rotateX(-22deg) rotateY(0);
    transform: rotateX(-22deg) rotateY(0);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotateX(-22deg) rotateY(360deg);
    -moz-transform: rotateX(-22deg) rotateY(360deg);
    -ms-transform: rotateX(-22deg) rotateY(360deg);
    -o-transform: rotateX(-22deg) rotateY(360deg);
    transform: rotateX(-22deg) rotateY(360deg);
  }

  100% {
    -webkit-transform: rotateX(-22deg) rotateY(0);
    -moz-transform: rotateX(-22deg) rotateY(0);
    -ms-transform: rotateX(-22deg) rotateY(0);
    -o-transform: rotateX(-22deg) rotateY(0);
    transform: rotateX(-22deg) rotateY(0);
  }
}
