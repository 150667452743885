body {
  #root {
    #app {
      #content-container {
        .quests {
          .explorer {
            .stage {
              block-size: 480px;
            }
            .quest-countdown {
              height: 80px;
              text-align: center;
              display: flex;
              font-size: 1.2em;

              div {
                color: var(--white);
              }
              .quest-state {
                vertical-align: middle;
                line-height: 80px;
                font-size: 1.3em;
                font-weight: 400;
              }
              font-weight: 300;

              .countdown {
                width: 100%;
                display: flex;
                justify-content: center;

                .ticker {
                  margin: 0 0.4em;

                  .digits {
                    display: flex;

                    .tick {
                      font-size: 1.1em;
                      font-weight: 500;
                    }

                    .tick-flip-panel {
                      background-color: #363636;
                    }
                  }

                  &.days {
                    .tick-flip-panel {
                      background-color: var(--green);
                    }
                  }

                  &.hours {
                    .tick-flip-panel {
                      background-color: var(--orange2);
                    }
                  }

                  &.mins,
                  &.complete {
                    .tick-flip-panel {
                      background-color: var(--red);
                    }
                  }

                  &.idle {
                    .tick-flip-panel {
                      background-color: var(--blue2);
                    }
                  }

                  .units {
                    font-size: 0.7rem;
                    text-align: center;
                  }
                }
              }
            }

            .quest-details {
              img {
                filter: drop-shadow(1px 1px 1px black);
              }

              &.is-loading {
                .quest-items {
                  position: relative;
                  overflow: hidden;
                  &::after {
                    width: 272px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    transform: translateX(-100%);
                    background-image: linear-gradient(
                      90deg,
                      rgba(#ccc, 0) 0,
                      rgba(#ccc, 0.2) 20%,
                      rgba(#ccc, 0.5) 60%,
                      rgba(#ccc, 0)
                    );
                    animation: shimmer 2s infinite;
                    content: '';
                  }

                  @keyframes shimmer {
                    100% {
                      transform: translateX(100%);
                    }
                  }
                }
              }

              .quest-details-tab {
                width: 100%;
                padding: 5px;
                color: var(--white);
                &.left-tab {
                  border-top-left-radius: 10px;
                }
                &.right-tab {
                  border-top-right-radius: 10px;
                }
                &:not(.is-active) {
                  box-shadow: inset 0px 0px 400px 110px rgba(0, 0, 0, 0.3);
                  filter: brightness(70%);
                  cursor: pointer;
                  color: #7a7a7a;
                  &:hover {
                    color: var(--orange2);
                  }
                }
              }

              .quest-items {
                background-repeat: no-repeat;
                background-size: 450%;
                background-position: top left;

                .question-activity-title {
                  color: var(--light2-text);
                  margin-top: 10px;
                  border-top-left-radius: 5px;
                  border-top-right-radius: 5px;
                }

                width: 272px;
                background-color: rgba(0, 0, 0, 0.5) !important;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                border: solid 1px var(--bd-grey2);

                font-weight: 300;
                border-radius: 10px;
                display: flex;
                flex-direction: column;

                .aborted {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding-bottom: 20px;
                  height: 134px;
                }

                .first-quest {
                  height: 117px;
                }

                .questing,
                .last-quest {
                  height: 135px;
                  i {
                    filter: drop-shadow(1px 1px 1px black);
                  }
                }

                .questing,
                .first-quest {
                  justify-content: space-evenly;
                }

                .questing,
                .first-quest,
                .last-quest {
                  overflow: auto;
                  .quest-action {
                    .button {
                      background: rgba(0, 0, 0, 0.65);
                    }
                  }
                  .pack-action {
                    .button {
                      background: rgba(0, 0, 0, 0.65);
                      font-size: 0.8em;
                    }
                  }
                }

                .quest-history {
                  overflow: auto;
                  height: 203px;
                }

                .questing,
                .first-quest,
                .last-quest,
                .quest-history {
                  display: flex;
                  flex-direction: column;

                  .countdown-item,
                  .pack-item,
                  .pack-action {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  }

                  .pack-item {
                    margin-top: -8px;
                    margin-bottom: 5px;
                    height: 80px;
                    position: relative;
                  }
                  .pack-action {
                    button {
                      height: 18px;
                    }
                  }

                  .quest-item {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 16px;

                    .token {
                      flex-grow: 0;
                    }

                    .item-name,
                    .item-balance {
                      height: 64px;
                      line-height: 64px;
                      vertical-align: middle;
                      text-align: left;
                    }

                    .item-name {
                      padding-left: 10px;
                      flex-grow: 1;
                      color: white;
                      font-size: 0.9em !important;
                      font-family: monospace;
                    }

                    .item-balance {
                      flex-grow: 0;
                      color: var(--orange2);
                      font-family: monospace;
                      font-size: 1em !important;
                    }
                  }
                }

                .grid :nth-child(n + 3) {
                  margin-top: 16px;
                }

                .questing {
                  justify-content: space-between;
                }
              }

              text-align: center;

              &.is-upper {
                text-transform: uppercase;
              }
              &.sub-title {
                font-size: 1.5rem;
                font-weight: 300;
              }

              img.token {
                width: 64px;
                height: 64px;
              }

              .land-token {
                width: 64px;
                height: 64px;

                img.token {
                  margin: 15px;
                  width: 38px;
                  height: 38px;
                }
              }
            }
          }
        }
      }
    }
  }
}
