body {
  #root {
    #app {
      #content-container {
        .quests {
          margin-top: -1em;

          &:before {
            content: '';
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            z-index: -1;
            -webkit-filter: opacity(0.5) blur(2px);
            -moz-filter: opacity(0.5) blur(2px);
            -o-filter: opacity(0.5) blur(2px);
            -ms-filter: bopacity(0.5) blur(2px);
            filter: opacity(0.5) blur(2px);
            background: url(../../assets/bg.png);
            background-position: top;
            background-size: cover;
          }
        }

        .mint-mercenary-button,
        .claim-mercenary-button {
          box-sizing: border-box;
          margin-top: 7px;
          background-repeat: no-repeat;
          background-size: 450%;
          background-position: top left;
          height: 479px;
          width: 275px;
          background-color: rgba(0, 0, 0, 0.35) !important;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          border: solid 1px var(--bd-grey2);
          border-radius: 10px;

          &:hover {
            transform-origin: bottom center;
            animation: mbscale 200ms ease-in-out forwards;
          }

          @keyframes mbscale {
            to {
              transform: scale(1.03);
            }
          }
        }

        .mint-mercenary-button {
          background-color: rgba(0, 0, 0, 0.35) !important;
        }

        .claim-mercenary-button {
          background-color: rgba(110, 93, 13, 0.35) !important;
        }
      }
    }
  }
}
