body {
  #root {
    #app {
      #content-container {
        #farm {
          &:before {
            content: '';
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            z-index: -1;
            -webkit-filter: opacity(0.5) blur(2px);
            -moz-filter: opacity(0.5) blur(2px);
            -o-filter: opacity(0.5) blur(2px);
            -ms-filter: bopacity(0.5) blur(2px);
            filter: opacity(0.5) blur(2px);
            background: url(../../assets/farmbg.png);
            background-position: top;
            background-size: cover;
          }

          height: 100%;

          .unallocated-amount,
          .allocated-amount,
          .growing-amount,
          .harvested-amount {
            &.is-active {
              h2 {
                color: var(--orange) !important;
              }
            }
          }

          .card-title {
            border-bottom: 1px solid #ffffff1c;
            margin-top: 0 !important;

            h2 {
              font-size: 1.1em !important;
              font-weight: 700 !important;
            }
          }

          .token {
            display: none;
            margin-right: 1em;
            height: 45px;

            @media screen and (min-width: 1215px) and (max-width: 1407px) {
              height: 40px;
            }

            @media screen and (max-width: 1215px) {
              height: 35px;
            }
          }

          .swiper-container {
            .tile {
              float: left;
            }

            .level {
              .button {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
