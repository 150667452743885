#begin-quest {
  width: 400px;
  height: 465px;
  overflow: hidden;

  .swiper {
    width: 360px;

    &.fullheight {
      height: 360px;
    }
  }

  .card {
    background-repeat: no-repeat;
    background-size: 450%;
    background-position: top left;

    color: var(--light2-text) !important;
    border-radius: 10px;

    .begin-quest-title {
      color: var(--white);
      padding: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: #0000004a;

      font-size: 1.3rem;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--light-text) !important;
    }

    .begin-quest-subtitle {
      font-size: 1.3rem;
      font-weight: 200;
      color: var(--light2-text) !important;
      background: #19191940;
      padding: 5px 0;
    }

    .begin-quest-content {
      padding: 1.2em;
      height: 360px;

      background-repeat: no-repeat;
      background-size: 450%;
      background-position: top left;
      border-radius: 15px;
    }

    .estimated-countdown {
      width: 100%;
      display: flex;
      justify-content: center;

      .ticker {
        margin: 0 0.4em;

        .digits {
          display: flex;

          .tick {
            font-size: 1.1em;
            font-weight: 500;
          }

          .tick-flip-panel {
            background-color: #363636;
          }
        }

        &.days {
          .tick-flip-panel {
            background-color: var(--green);
          }
        }

        &.hours {
          .tick-flip-panel {
            background-color: var(--orange2);
          }
        }

        &.mins,
        &.complete {
          .tick-flip-panel {
            background-color: var(--red);
          }
        }

        &.idle {
          .tick-flip-panel {
            background-color: var(--blue2);
          }
        }

        .units {
          font-size: 0.7rem;
          text-align: center;
        }
      }
    }

    .quest-item {
      position: relative;
      text-align: center;
      width: 250px;
      height: 250px;
      margin: 5px 3px 10px 55px;
      &.quest-disabled {
        filter: grayscale(100%);
      }
      img {
        border-radius: 10px;
      }
      .quest-title {
        text-transform: uppercase;
        color: var(--light2-text) !important;
        font-size: 2em !important;
        font-family: monospace;
        font-family: 'Black Ops One', monospace;

        position: absolute;
        width: 252px;
        bottom: 30%;
        left: 50%;
        transform: translate(-50%, 0);

        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);

        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        line-height: 40px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
      }
    }

    .equip-item {
      width: 100px;
      height: 165px;
      margin: 5px 3px 10px 3px;
      border: solid 1px var(--grey2);
      border-radius: 10px;

      background: radial-gradient(circle, rgba(97, 67, 57, 0.5) 0%, rgba(0, 0, 0, 1) 100%);

      text-align: center;

      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);

        transform-origin: center center;
        animation: scale1 300ms ease-in-out forwards;

        img {
          transform-origin: center center;
          animation: scale2 300ms ease-in-out forwards;
        }
        .item-title {
          div {
            transform-origin: center center;
            animation: scale3 300ms ease-in-out forwards;
          }
        }
        &:hover {
          border: solid 1px #00695c90;
        }
      }

      &.equip-item-selected {
        border: solid 1px var(--orange2) !important;
      }

      .item-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        height: 40px;
        margin-top: 1px;
        padding: 0 5px;
        background: #00695c80;

        div {
          margin-top: -3px;
        }
      }

      .item-name {
        text-transform: uppercase;
        color: antiquewhite;
        font-size: 0.7em !important;
        font-family: monospace;
      }

      .rarity-display {
        text-transform: uppercase;
        color: #6e6861;
        font-size: 0.7em !important;
        font-family: monospace;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &.unique {
        background: radial-gradient(circle, #4b4b4375 0%, rgba(0, 0, 0, 1) 100%);

        .rarity-display {
          color: antiquewhite;
          background-color: rgba(220, 20, 60, 0.39);
        }
      }
      &.legendary {
        background: radial-gradient(circle, #41413c60 0%, rgba(0, 0, 0, 1) 100%);
        .rarity-display {
          background-color: #ffd70085;
        }
      }
      &.epic {
        background: radial-gradient(circle, #33333145 0%, rgba(0, 0, 0, 1) 100%);
        .rarity-display {
          background-color: #c0c0c085;
        }
      }
      &.rare {
        background: radial-gradient(circle, #29292730 0%, rgba(0, 0, 0, 1) 100%);

        .rarity-display {
          background-color: #cd7f3285;
        }
      }

      .item-balance {
        color: var(--orange);
        font-family: monospace;
        font-size: 0.9em !important;
      }
    }
  }
  @keyframes scale1 {
    to {
      transform: scale(1.05);
    }
  }

  @keyframes scale2 {
    to {
      transform: scale(1.2);
    }
  }

  @keyframes scale3 {
    to {
      transform: scale(1.1);
    }
  }
}
