body {
  #root {
    #app {
      #content-container {
        #content {
          height: 100%;

          @media only screen and (max-width: 768px) {
            table,
            thead,
            tbody,
            th,
            td,
            tr {
              display: block;
            }
            thead tr {
              position: absolute;
              top: -9999px;
              left: -9999px;
            }
            tr {
              border: none;

              padding: 0.5em 0 1em 0;
            }
            tr:not(:last-child) {
              border-bottom: solid 1px #ffffff12;
            }
            td {
              border: none;
              padding-top: 0.5em !important;

              div {
                align-content: center;
                justify-content: center;

                &.cell_TitleText {
                  display: flex !important;
                }

                &.cell_Action {
                  text-align: center !important;
                }
              }
            }
          }

          .card-title {
            border-bottom: 1px solid #ffffff1c;
            margin-top: 0 !important;

            h2 {
              font-size: 1.1em !important;
              font-weight: 700 !important;
            }
          }

          .table {
            background-color: transparent;
            font-size: 1.1rem;
            table-layout: fixed;
            color: var(--light-text);

            &.small-text {
              td {
                div {
                  font-size: 85%;
                }
              }
            }
            &.table-grouped {
              th {
                border-bottom: none;
              }
            }
            thead {
              th {
                color: var(--light3-text);
                border-bottom: solid 1px #ffffff12;
                font-weight: 300;
                font-size: 0.8em;
                box-sizing: content-box;
              }
            }
            tbody {
              tr:hover {
                background-color: #16161630;
              }
              td {
                background-color: #16161670;
                padding: 15px !important;
                border: none;
                font-weight: 300;
                vertical-align: middle;
              }
            }

            @media only screen and (min-width: 769px) {
              tr:first-child {
                td:first-child {
                  border-top-left-radius: 10px;
                }
                td:last-child {
                  border-top-right-radius: 10px;
                }
              }
              tr:last-child {
                td:first-child {
                  border-bottom-left-radius: 10px;
                }
                td:last-child {
                  border-bottom-right-radius: 10px;
                }
              }
            }

            @media only screen and (max-width: 768px) {
              td:first-child {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
              }
              td:last-child {
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
              }
            }

            a {
              :not(button) {
                color: var(--light-link-blue);
              }
            }

            .double_cell {
              display: -webkit-flex;
              display: flex;
              -webkit-align-items: center;
              align-items: center;
              justify-content: center !important;
            }

            .cell {
              display: -webkit-flex;
              display: flex;
              flex-direction: column;
              justify-content: center !important;
            }

            .cell_TitleText {
              overflow: hidden;
              text-overflow: ellipsis;
              display: none;
              -webkit-align-items: center;
              align-items: center;
              font-size: 1.1rem;
            }

            .cell_PrimaryTitleText {
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
              -webkit-align-items: center;
              align-items: center;
              font-size: 1.1rem;
              justify-content: center !important;
            }

            .cell_PrimaryText {
              overflow: hidden;
              word-break: break-word;
              white-space: normal;
              display: block;
              -webkit-align-items: center;
              align-items: center;
              font-weight: 600;
              font-size: 1.1rem;
              text-align: center;

              &.cell_Heading {
                font-size: 1.1rem !important;
              }
            }

            .cell_SecondaryText {
              overflow: hidden;
              word-break: break-word;
              white-space: normal;
              display: block;
              font-size: 0.9rem;
              text-align: center;

              .value {
                font-weight: 600;
              }
            }

            .is-loading {
              background-color: transparent;

              &::after {
                border-color: transparent transparent #ddd #ddd !important;
              }
            }

            .cell_Action {
              text-align: right;
            }

            .tLogo {
              margin-right: 8px;
              position: relative;

              > img {
                margin-left: 15px;
                margin-left: -32px;
              }

              .tLogo_group {
                display: -webkit-flex;
                display: flex;
                justify-content: center;
                img:first-child {
                  z-index: 1;
                }
                img:nth-child(2) {
                  margin-left: -32px;
                }
              }

              .tExtra {
                position: absolute;
                right: -7px;
                top: -7px;
                width: 17.5px;
                height: 17.5px;
                min-width: 17.5px;
                min-height: 17.5px;
                z-index: 2;
              }

              img {
                &.token {
                  width: 50px;
                }
                &.token1 {
                  width: 50px;
                  // margin-left: 5px;
                  z-index: 1;
                }
                &.token1 {
                  width: 50px;
                  margin-left: -32px;
                  // margin-right: -10px;
                }
                &.nft {
                  width: 100px;
                }
                min-width: 30px;
                min-height: 30px;
                margin-right: 16px;
              }
            }
          }
        }
      }
    }
  }
}
