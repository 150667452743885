html {
  height: 100%;
  body {
    height: 100%;
    #root {
      height: 100%;
      #app {
        height: 100%;
        #main {
          overflow-y: auto;
          overflow-x: hidden;
          height: 100%;
          width: 100%;

          #content-container {
            &:before {
              content: '';
              position: fixed;
              left: 0;
              right: 0;
              top: 0;
              width: 100%;
              height: 100vh;
              z-index: -1;
              -webkit-filter: opacity(0.5) blur(2px);
              -moz-filter: opacity(0.5) blur(2px);
              -o-filter: opacity(0.5) blur(2px);
              -ms-filter: bopacity(0.5) blur(2px);
              filter: opacity(0.5) blur(2px);
              background: url(../assets/bg.png);
              background-position: top;
              background-size: cover;
            }

            height: 100%;
            width: 100%;

            @media screen and (min-width: 769px) {
              padding-top: 3em;
              padding-bottom: 3em;
            }
            @media screen and (max-width: 768px) {
              padding-top: 1em;
              padding-bottom: 1em;
            }

            .meme {
              &.is-half {
                max-width: 350px;
              }
              max-width: 600px;
              margin: 0 auto;
              background-color: rgba(0, 0, 0, 0.5) !important;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
              border-radius: 6px;
              border: solid 1px var(--bd-grey2);

              h3 {
                font-weight: 300;

                text-align: center;
                color: var(--white);

                &.is-upper {
                  text-transform: uppercase;
                }
                &.sub-title {
                  font-size: 1.5rem;
                  font-weight: 300;
                }
                &.is-body {
                  font-size: 1.2rem;
                  font-weight: 300;
                }
              }
            }

            .hero-body {
              height: 100%;
            }

            .is-center {
              display: flex;
              align-content: center;
              justify-content: center;
            }

            .switch + label::before {
              background-color: var(--bg-grey2);
            }
            .switch.is-active + label::after {
              background-color: var(--orange2);
            }
            .switch + label::before,
            .switch:focus + label::before,
            .switch + label::after,
            .switch:focus + label::after {
              outline: none;

              &:focus {
                outline: none;
              }
            }

            .tile {
              .card-title {
                font-size: 1.2em;
                font-weight: 700;
                color: var(--light-text);
                padding: 0 10px;
              }

              .card {
                font-weight: 300;
                background-color: var(--grey1) !important;
                border-radius: 10px;
                border: solid 1px var(--bd-grey1);
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                padding: 15px;
                display: flex;
                flex-direction: column;

                .info-control:last-child {
                  margin-bottom: 0;
                }

                &.is-button {
                  cursor: pointer;
                }

                .content {
                  margin: 0;
                }

                hr {
                  background-color: #ffffff1c;
                  height: 1px;
                  margin: 10px 0;
                }

                .narrow-content {
                  margin: 0 auto !important;
                  float: none !important;

                  @media screen and (min-width: 1215px) {
                    max-width: 40%;
                  }

                  @media screen and (min-width: 1024px) and (max-width: 1214px) {
                    max-width: 40%;
                  }

                  @media screen and (min-width: 769px) and (max-width: 1023px) {
                    max-width: 60%;
                  }

                  @media screen and (min-width: 380px) and (max-width: 768px) {
                    max-width: 80%;
                  }

                  @media screen and (max-width: 380px) {
                    max-width: 100%;
                  }
                }

                .tooltip {
                  position: relative;
                  display: inline-block;

                  &.icon-text {
                    margin-top: -1px;
                  }

                  .tooltip-text {
                    filter: drop-shadow(1px 2px 3px black);
                    visibility: hidden;

                    width: 200px;
                    text-align: center;
                    padding: 10px;
                    border-radius: 2px;
                    position: absolute;
                    z-index: 1000;
                    left: 50%;
                    margin-left: -100px;
                    margin-top: 28px;
                    background-color: #0a0a0aaa;
                    border-color: transparent;
                    color: var(--light-text);
                    font-size: 0.8em;
                    font-weight: 500;

                    @media screen and (min-width: 1215px) and (max-width: 1407px) {
                      margin-top: 28px;
                    }

                    @media screen and (max-width: 1215px) {
                      margin-top: 25px;
                    }
                  }

                  .tooltip-text-medium {
                    width: 250px;
                    margin-left: -125px;
                  }

                  .tooltip-text-wide {
                    width: 300px;
                    margin-left: -150px;

                    @media screen and (max-width: 400px) {
                      max-width: 250px;
                      margin-left: -125px;
                    }
                  }

                  &:hover,
                  &:checked {
                    .tooltip-text {
                      visibility: visible;
                      transition: visibility 0s linear 0.3s;
                    }
                  }
                }

                .title {
                  margin-bottom: 0.3em;

                  margin-left: -20px;
                  margin-right: -20px;

                  .tabs {
                    border-radius: 6px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    background-color: var(--bk-grey3);

                    .tab-title {
                      margin-top: 3px;

                      @media screen and (min-width: 1215px) and (max-width: 1407px) {
                        margin-top: 2px;
                      }

                      @media screen and (max-width: 1215px) {
                        margin-top: 2px;
                      }

                      color: var(--light-text);
                    }

                    li {
                      .token {
                        margin-right: 1em;
                        height: 35px;

                        @media screen and (min-width: 1215px) and (max-width: 1407px) {
                          height: 30px;
                        }

                        @media screen and (max-width: 1215px) {
                          height: 25px;
                        }
                      }

                      &.is-active {
                        background-color: var(--bg-grey4);
                        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
                      }
                      &.disabled {
                        a {
                          img {
                            opacity: 0.4;
                            filter: alpha(opacity=40);
                          }
                        }
                      }
                      &:not(.is-active) {
                        background-color: var(--bg-grey3);
                      }

                      @media (pointer: coarse), (hover: none) {
                        [title] {
                          position: relative;
                          display: inline-flex;
                          justify-content: center;
                        }
                        [title]:focus::after {
                          content: attr(title);
                          position: absolute;
                          top: 90%;
                          color: #000;
                          background-color: #fff;
                          border: 1px solid;
                          width: fit-content;
                          padding: 3px;
                        }
                      }
                    }
                  }
                }

                .action-control {
                  width: 100%;
                  list-style: none;
                  flex-wrap: wrap;
                  gap: 10px;

                  &.no-gap {
                    gap: 0;
                  }

                  align-content: center;
                  justify-content: center;
                  align-items: center;

                  display: flex;
                  padding: 0;
                  margin: 0;

                  &.is-vertical {
                    flex-direction: column;
                  }

                  li {
                    &.is-center {
                      flex: none !important;
                      display: block;
                      // width: max-content;
                    }

                    margin: 2px;
                    flex: 1 0;

                    &.action-form {
                      .field.has-addons {
                        display: flex;
                        justify-content: center;

                        .input-control {
                          flex: 1;
                        }
                      }
                    }

                    .buttons {
                      flex-wrap: unset;
                    }
                  }

                  .button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 0.5em 1em;
                  }
                }

                .info-control {
                  color: var(--light-text);
                  display: flex;
                  align-content: center;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  flex-direction: row;
                  width: 100%;
                  margin: 1em 0;
                  padding: 0 2px;

                  &.no-wrap {
                    flex-wrap: nowrap;
                  }

                  .info-value {
                    display: -webkit-box !important;
                  }

                  .action {
                    margin-top: -0.6em;
                    margin-left: 1em;
                    display: flex;
                  }

                  .countdown {
                    margin-top: -0.3em;
                    display: flex;

                    .ticker {
                      margin: 0 0.4em;

                      .digits {
                        display: flex;

                        .tick {
                          font-size: 1.3em;
                          font-weight: 500;
                        }

                        .tick-flip-panel {
                          background-color: #363636;
                        }
                      }

                      &.days {
                        .tick-flip-panel {
                          background-color: var(--green);
                        }
                      }

                      &.hours {
                        .tick-flip-panel {
                          background-color: var(--orange2);
                        }
                      }

                      &.mins {
                        .tick-flip-panel {
                          background-color: var(--red);
                        }
                      }

                      .units {
                        font-size: 0.7rem;
                        text-align: center;
                      }
                    }
                  }

                  h2 {
                    margin-top: 0;
                    font-size: 1rem;
                    color: var(--light-text);

                    &.is-center {
                      flex: none !important;
                    }

                    &.info {
                      font-size: 1.2rem;
                      font-weight: 300;
                      margin-right: 1em;
                      margin-bottom: 0.5em;
                      line-height: 1.125;

                      @media screen and (min-width: 1215px) and (max-width: 1407px) {
                        font-size: 1.1rem;
                      }

                      @media screen and (max-width: 1215px) {
                        font-size: 1rem;
                      }
                    }

                    &.info-tab {
                      cursor: pointer;
                      color: #7a7a7a;
                      &:hover {
                        color: var(--orange2);
                      }
                    }

                    &.value {
                      font-size: 1.2rem;
                      font-weight: 600;
                      flex: 1;
                      white-space: normal;

                      .pre {
                        margin-right: 5px;
                      }

                      .sub {
                        font-size: 1rem;
                        margin-left: 10px;
                      }

                      .post {
                        font-size: 0.7rem;
                        margin-top: 4px;

                        .is-loading {
                          background-color: transparent;
                          margin-top: -16px;

                          &::after {
                            border-color: transparent transparent #ddd #ddd !important;
                          }
                        }
                      }

                      @media screen and (min-width: 1215px) and (max-width: 1407px) {
                        font-size: 1.1rem;

                        .post {
                          font-size: 0.6rem;
                        }
                      }

                      @media screen and (max-width: 1215px) {
                        font-size: 1rem;

                        .post {
                          font-size: 0.6rem;
                        }
                      }

                      @media screen and (min-width: 380px) {
                        .post {
                          float: right !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
