html {
  body {
    #root {
      #app {
        #main {
          #content-container {
            .barn {
              &:before {
                content: '';
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                width: 100%;
                height: 100vh;
                z-index: -1;
                -webkit-filter: opacity(0.5) blur(2px);
                -moz-filter: opacity(0.5) blur(2px);
                -o-filter: opacity(0.5) blur(2px);
                -ms-filter: bopacity(0.5) blur(2px);
                filter: opacity(0.5) blur(2px);
                background: url(../../assets/barnbg.png);
                background-position: top;
                background-size: cover;
              }

              .sticky-craft-table {
                .craft-status {
                  background-color: rgba(0, 0, 0, 0.9) !important;
                  position: sticky;
                  top: 0;
                  margin: 0;
                  height: 4em;
                  flex-shrink: 0;
                }

                .grid {
                  overflow-y: auto;
                  padding: 0.5em 0;
                  margin: 0 !important;
                }

                .craft-controls {
                  flex-shrink: 0;
                }

                display: flex;
                flex-direction: column;
                position: fixed;
                left: 0;
                bottom: 0;
                z-index: 1;
                background-color: rgba(0, 0, 0, 0.9) !important;
                width: 100%;
                max-height: 50%;
                padding: 0 1em;
              }

              .zoomed-item,
              .zoomed-pack,
              .zoomed-buy-pack {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: flex-start;
                border: solid 1px var(--grey2);
                border-radius: 10px;

                background: radial-gradient(circle, rgba(107, 103, 64, 0.5) 0%, rgba(0, 0, 0, 1) 100%);
                width: 400px;
                height: 520px;
                overflow: hidden;

                .item-name {
                  text-transform: uppercase;
                  color: antiquewhite;
                  font-size: 1em !important;
                  font-family: monospace;
                }

                .item-description {
                  overflow-y: auto;
                  max-height: 65px;
                  color: antiquewhite;
                  font-size: 0.9em !important;
                  font-family: monospace;
                }

                .item-description-fixed {
                  color: antiquewhite;
                  font-size: 0.9em !important;
                  font-family: monospace;
                }

                .item-balance {
                  color: var(--orange);
                  font-family: monospace;
                  font-size: 0.9em !important;
                }

                .unrevealed {
                  align-self: center;
                  background: transparent;
                  height: 320px;
                }

                button {
                  align-self: center;
                }

                .modal-close {
                  -webkit-animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
                  animation: text-focus-in 0.6s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
                  position: absolute;
                  right: 5px;
                  top: 5px;
                }

                -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
              }

              .zoomed-pack,
              .zoomed-buy-pack {
                height: 600px;
              }

              .card {
                box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 4px rgba(0, 0, 0, 0.19);
                // background-image: linear-gradient(rgba(10, 10, 10, 0.9), rgba(10, 10, 10, 0.8)),
                //   url(../../assets/strawbg.png);
                background-position: top;
                background-size: 600px;

                background-size: 500%;

                padding: 15px;

                .empty-items {
                  color: antiquewhite;
                  font-family: monospace;
                  font-size: 18px;
                  text-transform: uppercase;
                }

                .buy-pack-button {
                  box-sizing: border-box;
                  margin-top: 7px;
                  background-repeat: no-repeat;
                  background-size: 450%;
                  background-position: top left;
                  height: 100px;
                  width: 100px;
                  background-color: rgba(0, 0, 0, 0.35) !important;
                  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
                  border: solid 1px var(--bd-grey2);
                  border-radius: 10px;
                }

                .grid {
                  display: grid;
                  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
                  grid-row-gap: 15px;
                  grid-column-gap: 11px;

                  &.recipe-selected {
                    grid-template-columns: none;
                  }

                  .grid-item-container {
                    overflow: hidden;
                    padding: 3px;

                    .grid-item {
                      width: 100px;
                      height: 158px;
                      background: radial-gradient(circle, rgba(36, 35, 35, 0.5) 0%, rgba(0, 0, 0, 1) 100%);
                      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
                      border: solid 1px #936d3b41;
                      line-height: 0.8em;
                      border-radius: 10px;
                      text-align: center;

                      img {
                        height: 100px;
                      }

                      .item-title {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 5px;
                        height: 40px;
                        margin-top: 1px;
                        padding: 0 5px;

                        div {
                          margin-top: -3px;
                        }
                      }

                      &.is-material {
                        &:hover {
                          border: solid 1px #ff980060;
                        }

                        .item-title {
                          background: #ff980030;
                        }
                      }

                      &.is-item {
                        &:hover {
                          border: solid 1px #00695c90;
                        }

                        .item-title {
                          background: #00695c80;
                        }
                      }

                      &.is-artifact {
                        &:hover {
                          border: solid 1px #69000090;
                        }

                        .item-title {
                          background: #69000080;
                        }
                      }

                      .whole-artifact {
                        -webkit-animation: glow 2s infinite alternate;
                        animation: glow 2s infinite alternate;
                      }

                      &.is-pack {
                        height: 144px;

                        &:hover {
                          border: solid 1px #2e319480;
                        }

                        .item-title {
                          background: #2e319485;
                        }
                      }

                      &.is-buy-pack {
                        height: 144px;

                        &:hover {
                          border: solid 1px #943f8660;
                        }

                        .item-title {
                          background: #943f8630;
                        }
                      }

                      &.is-approve-buy {
                        height: 144px;

                        &:hover {
                          border: solid 1px #dfdc3590;
                        }

                        .item-title {
                          background: #dfdc3565;
                        }
                      }

                      &:hover {
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);

                        transform-origin: center center;
                        animation: scale1 300ms ease-in-out forwards;

                        img {
                          transform-origin: center center;
                          animation: scale2 300ms ease-in-out forwards;
                        }

                        .item-title {
                          div {
                            transform-origin: center center;
                            animation: scale3 300ms ease-in-out forwards;
                          }
                        }

                        @keyframes scale1 {
                          to {
                            transform: scale(1.05);
                          }
                        }

                        @keyframes scale2 {
                          to {
                            transform: scale(1.2);
                          }
                        }

                        @keyframes scale3 {
                          to {
                            transform: scale(1.1);
                          }
                        }
                      }

                      &.unique {
                        background: radial-gradient(circle, #4b4b4375 0%, rgba(0, 0, 0, 1) 100%);

                        .rarity-display {
                          color: antiquewhite;
                          background-color: rgba(220, 20, 60, 0.39);
                        }
                      }

                      &.legendary {
                        background: radial-gradient(circle, #41413c60 0%, rgba(0, 0, 0, 1) 100%);

                        .rarity-display {
                          background-color: #ffd70065;
                        }
                      }

                      &.epic {
                        background: radial-gradient(circle, #33333145 0%, rgba(0, 0, 0, 1) 100%);

                        .rarity-display {
                          background-color: #c0c0c065;
                        }
                      }

                      &.rare {
                        background: radial-gradient(circle, #29292730 0%, rgba(0, 0, 0, 1) 100%);

                        .rarity-display {
                          background-color: #cd7f3265;
                        }
                      }

                      .claim-items {
                        overflow-y: scroll;
                        height: 70px;
                      }

                      .claim-title {
                        text-transform: uppercase;
                        color: #6e6861;
                        font-size: 0.9em !important;
                        font-family: monospace;
                      }

                      .item-name,
                      .claim-name {
                        text-transform: uppercase;
                        color: lightgray;
                        font-size: 0.7em !important;
                        font-family: monospace;
                      }

                      .item-description {
                        text-transform: uppercase;
                        color: antiquewhite;
                        font-size: 0.7em !important;
                        font-family: monospace;
                      }

                      .rarity-display {
                        line-height: 13px;
                        height: 13px;
                        text-transform: uppercase;
                        background-color: #44444450;
                        color: #c4beb6;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                        font-size: 0.6em !important;
                        font-family: monospace;
                        text-align: left;
                        padding: 0 6px;

                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        .item-rarity {
                          padding-top: 1px;
                        }

                        .item-icon {
                          padding-top: 1px;
                        }
                      }

                      &.is-health {
                        .item-icon {
                          color: #008000;
                        }
                      }

                      &.is-morale {
                        .item-icon {
                          color: #ffa500;
                        }
                      }

                      .item-balance,
                      .item-amount,
                      .claim-amount {
                        color: var(--orange);
                        font-family: monospace;
                        font-size: 0.9em !important;

                        .input {
                          text-align: right;
                          margin-top: -1px;
                          width: 40px;
                          height: 20px;
                          font-size: 0.9em !important;
                        }
                      }

                      .item-amount {
                        font-size: 0.7em !important;
                      }

                      .pack-container {
                        height: 135px;

                        &>div {
                          margin-top: -30px;
                          margin-left: -30px;
                        }
                      }
                    }

                    .crafting-item-controls {

                      .minus-button {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                      }
    
                      .plus-button {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                      }

                      padding-top: .5em;
                      padding-left: .2em;                      
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}