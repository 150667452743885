html {
  body {
    #root {
      #app {
        #main {
          #content-container {
            .saloon {
              &:before {
                content: '';
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                width: 100%;
                height: 100vh;
                z-index: -1;
                -webkit-filter: opacity(0.5) blur(2px);
                -moz-filter: opacity(0.5) blur(2px);
                -o-filter: opacity(0.5) blur(2px);
                -ms-filter: opacity(0.5) blur(2px);
                filter: opacity(0.5) blur(2px);
                background: url(../../assets/saloonbg.png);
                background-position: top;
                background-size: cover;
              }
            }

            .card {
              &.mini-game {
                box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 4px rgba(0, 0, 0, 0.19);
                background: url(../../assets/woodbg.png);
                background-position: top;
                background-size: 1200px;
                padding: 15px;

                .card-title {
                  border-bottom: 2px solid #050403;
                }

                .card-content,
                .content {
                  height: 100%;
                  border-radius: 10px;
                  background-color: rgba(0, 0, 0, 0.5) !important;
                  padding: 20px;
                }

                .tick-flip-panel,
                .tick-flip-panel-back {
                  background: url(../../assets/lightwood.png);
                }

                .tick-flip-panel-text-wrapper {
                  color: #453631;
                  font-weight: 600;
                }
              }
            }

            .moonshine-banner-container {
              justify-content: center;

              .moonshine-banner-img {
                height: 100px;
                border-radius: 10px;
                border: solid 1px var(--bd-grey1);
                box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.2), 0 6px 20px 4px rgba(0, 0, 0, 0.19);
              }

              @media screen and (max-width: 500px) {
                .moonshine-banner {
                  font-size: 54px;
                  padding: 0 1rem !important;
                }
              }

              @media screen and (max-width: 350px) {
                .moonshine-banner {
                  font-size: 44px;
                  padding: 0 1rem !important;
                }
              }
            }

            .no-game-animation-spacing {
              @media screen and (min-width: 769px) {
                padding-top: 2.7em !important;
              }

              @media screen and (min-width: 819px) {
                padding-top: 1em !important;
              }
            }

            .game-animation {
              position: absolute;
              width: 70px;
              height: 70px;
              bottom: 92px;
              right: 35px;
            }

            h2 {
              &.winning {
                span {
                  color: var(--orange);
                }
              }
              &.losing {
                span {
                  color: var(--red);
                }
              }
              &.winning,
              &.losing {
                animation: animate 1.5s linear infinite;
              }
            }

            .red-button {
              cursor: pointer;
              position: relative;
              border-radius: 2rem;

              border: none;
              background-image: linear-gradient(-180deg, #ff0000 0%, #460000 100%);

              // box-shadow: none, 0 5px 7px 0 rgba(195, 22, 117, 0.5), 0 -3px 10px rgb(55, 27, 68) inset;

              // box-shadow: 0 1rem 1.25rem 0 rgba(195, 22, 117, 0.5), 0 -0.25rem 1.5rem rgb(55, 27, 68) inset,
              //   0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset, 0 0.25rem 0.5rem 0 rgba(180, 70, 207, 1) inset;
            }

            .min-button,
            .max-button {
              padding: 18px;
            }

            .red-button span {
              color: white;
              //background-image: linear-gradient(0deg, #8296ee 0%, #fefafd 100%);
              -webkit-background-clip: text;
              background-clip: text;
            }

            .red-button::before {
              content: '';
              display: block;
              height: 0.15rem;
              position: absolute;
              top: 0.5rem;
              left: 50%;
              transform: translateX(-50%);
              width: calc(100% - 7.5rem);
              background: #fff;
              border-radius: 100%;

              opacity: 0.7;
              background-image: linear-gradient(
                -270deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 20%,
                #ffffff 80%,
                rgba(255, 255, 255, 0) 100%
              );
            }

            .red-button::after {
              content: '';
              display: block;
              height: 0.25rem;
              position: absolute;
              bottom: 0.75rem;
              left: 50%;
              transform: translateX(-50%);
              width: calc(100% - 7.5rem);
              background: #fff;
              border-radius: 100%;

              filter: blur(1px);
              opacity: 0.05;
              background-image: linear-gradient(
                -270deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 20%,
                #ffffff 80%,
                rgba(255, 255, 255, 0) 100%
              );
            }

            thead {
              th {
                text-align: center !important;
              }
            }
            .action-dropdown {
              .dropdown-content {
                background-color: var(--bg-grey2);
                border: 1px solid var(--orange2);
                display: flex;
                flex-direction: column;
                .button {
                  color: var(--light-text) !important;
                }
                .button:hover {
                  background-color: var(--bg-grey1) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}
