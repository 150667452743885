#allocate-xp {
  width: 380px;

  .modal-close {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .card {
    background-repeat: no-repeat;
    background-size: 450%;
    background-position: top left;
    border: solid 1px var(--bd-grey2);
    border-radius: 10px;

    background-color: #000;
    box-shadow: -4px 5px 15px -3px rgb(0, 0, 0 / 20%);

    padding: 0 0 1.6em;

    .allocate-stats-title {
      color: var(--white);
      padding: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: #0000004a;

      font-size: 1.3rem;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--light-text) !important;
    }
    .allocate-stats-subtitle {
      margin-top: 5px;
      font-size: 1.2rem;
      font-weight: 300;
      color: var(--light2-text) !important;
    }

    .allocate-stats-remaining {
      font-size: 0.9rem;
      font-weight: 300;
      color: var(--light2-text) !important;
    }

    .boosts-empty {
      color: red;
    }

    .allocate-stats-stats {
      input {
        text-align: center;
      }
      .minus-button {
        border-bottom-left-radius: 0;
      }
      .plus-button {
        border-bottom-right-radius: 0;
      }
      .boost-button {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .character-stat-list-item {
      button {
        height: 30px;
      }
      &.is-disabled {
        button {
          opacity: 0.5;
        }
      }
      .boosted {
        color: green;
      }
      .boosted-from {
        font-size: small;

        i {
          font-size: small;
          color: var(--grey3);
        }
      }
    }
  }
}
