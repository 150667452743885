#mint-mercenary {
  width: 340px;

  .swiper {
    width: 300px;
    overflow: hidden !important;
    height: 80px;
    text-align: center;

    .swiper-button-prev:after,
    .swiper-button-next:after {
      font-size: 32px !important;
    }
  }
  .modal-close {
    right: -1px !important;
    top: -1px !important;
  }

  canvas {
    border-radius: 10px;
  }
  .card {
    background-repeat: no-repeat;
    background-size: 200%;
    background-position: center;
    border: solid 1px var(--bg-grey2);

    color: var(--light3-text) !important;
    border-radius: 10px;

    .mint-mercenary-title {
      color: var(--white);
      padding: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: #0000004a;

      font-size: 1.3rem;
      font-weight: 600;
      text-transform: uppercase;

      color: var(--light-text) !important;
    }
    .mint-mercenary-subtitle {
      font-size: 1.2rem;
      font-weight: 200;
      color: var(--light2-text) !important;
    }

    .mint-mercenary-content {
      padding: 1.2em;
    }

    .character-trait-list-item {
      color: var(--light3-text) !important;
    }
  }
}
