.collapsible-card {
  .is-collapsible {
    &.is-active {
      overflow-y: visible !important;
    }
  }

  .icon {
    vertical-align: super;
  }
}

article {
  height: intrinsic;

  .card-title {
    h2.info {
      height: 36px;
      padding: 8px 10px;
      border-radius: 5px;
      margin-top: 1px !important;
      background-color: #00000040;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
