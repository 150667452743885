body #root #app {
  .navbar {
    background-color: #000000aa;

    .navbar-brand {
      background-color: #000000aa;
    }

    .navbar-menu {
      background-color: #000000aa;
      a.navbar-item:focus,
      a.navbar-item:focus-within,
      a.navbar-item:hover,
      a.navbar-item.is-active,
      .navbar-link:focus,
      .navbar-link:focus-within,
      .navbar-link:hover,
      .navbar-link.is-active {
        background-color: #000000aa;
      }

      max-height: calc(100vh - 3.25rem);
      min-height: 74px;
      overflow: visible;
    }

    .navbar-burger {
      height: 74px;
      color: var(--white1);
    }

    .dropdown-content {
      .navbar-item {
        font-size: 16px;
      }
    }

    .navbar-item {
      text-align: center;
      color: white;

      font-size: .9em;
      font-weight: 500;

      @media screen and (min-width: 1215px) {
        font-size: 1.1em;
      }

      .logo {
        max-height: 100% !important;
      }

      &.logo-text {
        padding-left: 5px;
        font-family: Audiowide;
        font-size: 28px;
        cursor: pointer;
      }
    }

    .dropdown {
      .dropdown-content {
        background-color: #363636;
        display: flex;
        flex-direction: column;
        .button:hover {
          background-color: #414141 !important;
        }
      }
    }

    .testnet {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }
}
