body {
  #root {
    #app {
      #content-container {
        #landing {
          height: 100%;

          .container {
            .news {
              width: 300px;
            }

            span {
              font-weight: 300;

              &.is-upper {
                text-transform: uppercase;
              }

              h1 {
                font-size: 3.25rem;
                text-align: center;
                color: var(--white);
              }

              h2 {
                text-align: center;
                color: var(--white);

                &.sub-title {
                  font-size: 1.75rem;
                  font-weight: 300;
                  color: black;
                }
              }

              h3 {
                text-align: center;
                color: var(--white);

                &.sub-title {
                  font-size: 1.5rem;
                  font-weight: 300;
                }
              }

              h4 {
                text-align: center;
                color: var(--white);

                &.sub-title {
                  font-size: 1.25rem;
                  font-weight: 300;
                }
              }
            }

            #field {
              display: block;
              margin: auto;
              display: inline-block;
              position: relative;
              width: 100%;
              overflow: hidden;
              padding: 2em;
            }
          }
        }
      }
    }
  }
}

.socials {
  bottom: 2em;
  right: 0;
  position: fixed;
  height: 150px;
  width: 200px;

  .medium,
  .discord,
  .twitter {
    align-items: center;
    border-radius: 24px 0 0 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    display: flex;
    padding: 3px;
    text-decoration: none;
    z-index: 3;
    .icon {
      height: 32px;
      width: 32px;
    }
  }

  .medium,
  .discord,
  .twitter {
    &:hover {
      width: 45px;
    }
  }

  .medium {
    background: black;
    color: white;
  }

  .discord {
    background: #404eed;
    color: white;
  }

  .twitter {
    background: #55acee;
    color: white;
  }
}
