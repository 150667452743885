#buy-box {
  max-width: 500px;

  .modal-close {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .card {
    background-repeat: no-repeat;
    background-size: 450%;
    background-position: top left;
    border: solid 1px var(--bd-grey2);
    border-radius: 10px;

    background-color: #000;
    box-shadow: -4px 5px 15px -3px rgb(0, 0, 0 / 20%);

    padding: 0 0 1.6em;

    .buy-box-title {
      color: var(--white);
      padding: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: #0000004a;

      font-size: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    
    .buy-box-subtitle {
      font-size: 1.1rem;
      font-weight: 200;
      color: var(--light-text) !important;
    }

    .buy-box-content {
      padding: 1.2em;
    }

    .buy-button {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .size-controls {
      input {
        text-align: center;
      }
      .minus-button {
        border-bottom-left-radius: 0;
      }
      .plus-button {
        border-bottom-right-radius: 0;
      }
    }
  }
}
