html {
  body {
    #root {
      #app {
        #main {
          #content-container {
            .pools {
              &:before {
                content: '';
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                width: 100%;
                height: 100vh;
                z-index: -1;
                -webkit-filter: opacity(0.7) blur(2px);
                -moz-filter: opacity(0.7) blur(2px);
                -o-filter: opacity(0.7) blur(2px);
                -ms-filter: bopacity(0.7) blur(2px);
                filter: opacity(0.7) blur(2px);
                background: url(../../assets/poolsbg.png);
                background-position: top;
                background-size: cover;
              }

              .liquidity-action {
                width: 400px;
                padding: 1em;
                overflow: hidden;
                .modal-close {
                  -webkit-animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
                  animation: text-focus-in 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
                  position: absolute;
                }

                -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
                animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
              }
            }
            thead {
              th {
                text-align: center !important;
              }
            }
            .action-dropdown {
              .dropdown-content {
                background-color: var(--bg-grey2);
                border: 1px solid var(--orange2);
                display: flex;
                flex-direction: column;
                .button {
                  color: var(--light-text) !important;
                }
                .button:hover {
                  background-color: var(--bg-grey1) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
