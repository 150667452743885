html {
  body {
    #root {
      #app {
        #main {
          #content-container {
            .bank {
              &:before {
                content: '';
                position: fixed;
                left: 0;
                right: 0;
                top: 0;
                width: 100%;
                height: 100vh;
                z-index: -1;
                -webkit-filter: opacity(0.5) blur(2px);
                -moz-filter: opacity(0.5) blur(2px);
                -o-filter: opacity(0.5) blur(2px);
                -ms-filter: bopacity(0.5) blur(2px);
                filter: opacity(0.5) blur(2px);
                background: url(../../assets/walletbg.png);
                background-position: top;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }
}
