#restore-stat {
  width: 400px;

  .swiper {
    width: 360px;
  }

  .modal-close {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .card {
    background-repeat: no-repeat;
    background-size: 450%;
    background-position: top left;

    color: var(--light3-text) !important;
    border-radius: 10px;

    .restore-stat-title {
      color: var(--white);
      padding: 10px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: #0000004a;

      font-size: 1.5rem;
      font-weight: 600;
      text-transform: uppercase;
      color: var(--light-text) !important;
    }
    .restore-stat-subtitle {
      font-size: 1.2rem;
      font-weight: 200;
      color: var(--light2-text) !important;
    }

    .restore-stat-content {
      padding: 1.2em;
    }

    .stat-container {
      background-color: var(--black);
      border-radius: 20px;
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      .stat-bar {
        padding: 2px 8px 0;
        height: 22px;
        font-size: 0.8em;
        color: white;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

        &.stat-full {
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        transition: all 0.5s;
      }
    }

    .restore-stat-amount {
      input {
        text-align: center;
      }
      .minus-button {
        border-bottom-left-radius: 0;
      }
      .plus-button {
        border-bottom-right-radius: 0;
      }
      .restore-button {
        margin-top: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .stat-restore-item {
      width: 100px;
      height: 147px;
      margin: 5px 3px 10px 3px;
      border: solid 1px var(--grey2);
      border-radius: 10px;

      background: radial-gradient(circle, rgba(97, 67, 57, 0.5) 0%, rgba(0, 0, 0, 1) 100%);

      text-align: center;

      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);

        transform-origin: center center;
        animation: scale1 300ms ease-in-out forwards;

        img {
          transform-origin: center center;
          animation: scale2 300ms ease-in-out forwards;
        }
        .item-title {
          div {
            transform-origin: center center;
            animation: scale3 300ms ease-in-out forwards;
          }
        }
        &:hover {
          border: solid 1px #00695c90;
        }
      }

      &.stat-restore-item-selected {
        border: solid 1px var(--orange2) !important;
      }

      @keyframes scale1 {
        to {
          transform: scale(1.05);
        }
      }

      @keyframes scale2 {
        to {
          transform: scale(1.2);
        }
      }

      @keyframes scale3 {
        to {
          transform: scale(1.1);
        }
      }

      .item-title {
        background: #00695c80;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      .item-name {
        text-transform: uppercase;
        color: antiquewhite;
        font-size: 0.7em !important;
        font-family: monospace;
      }

      .rarity-display {
        text-transform: uppercase;
        color: #6e6861;
        font-size: 0.7em !important;
        font-family: monospace;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &.unique {
        background: radial-gradient(circle, #4b4b4375 0%, rgba(0, 0, 0, 1) 100%);

        .rarity-display {
          color: antiquewhite;
          background-color: rgba(220, 20, 60, 0.39);
        }
      }
      &.legendary {
        background: radial-gradient(circle, #41413c60 0%, rgba(0, 0, 0, 1) 100%);
        .rarity-display {
          background-color: #ffd70085;
        }
      }
      &.epic {
        background: radial-gradient(circle, #33333145 0%, rgba(0, 0, 0, 1) 100%);
        .rarity-display {
          background-color: #c0c0c085;
        }
      }
      &.rare {
        background: radial-gradient(circle, #29292730 0%, rgba(0, 0, 0, 1) 100%);

        .rarity-display {
          background-color: #cd7f3285;
        }
      }

      .item-balance {
        color: var(--orange);
        font-family: monospace;
        font-size: 0.9em !important;
      }
    }
  }
}
